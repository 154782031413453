<template>
  <div>
    <div class="wrapper-row fp-section fp-table wrapper-padding d-flex flex-row top-content nsb-section">
      <div class="wrapper-row-inner max-w row">
        <h3 class="text-white xbold">
          WERDE TEIL<br />
          UNSERES TEAMS!
        </h3>
        <div class="wrapper-row-inner col-md-6 job-desc">
          <p>
            Wir freuen uns, wenn du dich auf unsere offenen Stellen hin bewirbst. Wenn du denkst, unsere Team kann auf
            keinen Fall auf deine herausragenden Kompetenzen verzichten, kannst du dich auch gerne initiativ Bewerben.
            Deine Initiativbewerbung kann du an info@die-etagen.de senden.
          </p>
        </div>

        <div class="wrapper-row-inner col-md-6 job-desc">
          <ul>
            <li v-for="(key, index) in jobs" :key="index">
              – {{ key.category }} <br />
              <a :href="key.link" class="text-white xbold">{{ key.name }}</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="wrapper-row fp-section fp-table wrapper-padding d-flex flex-column top-content nsb-section gap-3">
      <div class="wrapper-row-inner max-w row d-flex flex-column text-center">
        <h2>Offene Stellen</h2>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
        <h3>Junior Art Director (m/w/d)</h3>
        <p>&nbsp;</p>
        <h4>Deine Aufgaben</h4>
        <ul>
          <li>Konzeption und Gestaltung Crossmedialer Medien (print & online)</li>
          <li>Ganzheitliche Gestaltung unterschiedlicher Medien und Anwendungen (print & online)</li>
          <li>UI/UX Konzeption und Gestaltung</li>
          <li>Brand Design</li>
          <li>Eigenverantwortliches und selbstständiges arbeiten</li>
        </ul>
        <h4>Das bringst du mit</h4>
        <ul>
          <li>Abgeschlossenes Kommunikationsdesign Studium oder ein vergleichbares</li>
          <li>Erfahrung mit (Web-)Gestaltung im Kontext von Markenbildung und Markenkommunikation von Vorteil</li>
          <li>Gute Kenntnisse im Umgang mit den Adobe Programmen (insbesondere XD, After Effects, Indesign, Photoshop, Illustrator)</li>
          <li>Interesse an Typografie, einen Blick auf Designtrends</li>
          <li>Berufserfahrung in einer ähnlichen Position wäre wünschenswert aber kein muss</li>
          <li>Erste Erfahrungen mit Wordpress von Vorteil</li>
          <li>Kenntnisse in HTML5 und CSS von Vorteil</li>
          <li>Teamfähigkeit und Kommunikationsstärke</li>
        </ul>
        <p>
          Da für uns der Zusammenhalt im Team super wichtig ist, wünschen wir uns, dass Du zumindest Zeitweise in Osnabrück vor Ort sein kannst. Generell unterstützen wir freies Arbeiten in der ganzen Welt, aber die Erfahrung hat gezeigt, dass gerade im Bereich der Kreation regelmäßiger Face-to-Face Austausch für uns wichtig sind.
          Außerdem ist es für uns wichtig, dass Du Deutsch mindestens auf dem C1 Niveau sprichst.
        </p>
        <h4>Unser Angebot für dich</h4>
        <ul>
          <li>Intensiver Austausch: Dazulernen, eigenes Wissen einbringen und weitergeben</li>
          <li>Wir arbeiten Dich in Ruhe ein und lassen Dich nicht "ins kalte Wasser springen"</li>
          <li>Freie Zeiteinteilung / Arbeiten von Zuhause - ganz wie es für Dich passt.</li>
          <li>Tolles Team! Wir stehen füreinander ein - und gehen einmal im Jahr zusammen auf große Fahrt</li>
          <li>Flache Hierarchien, kurze Entscheidungswege und Freiraum, Dich selbst zu entfalten</li>
          <li>Wir kümmern uns um Dich: Möglichkeit für persönliches Coaching zu verschiedensten Themen</li>
          <li>Wir fördern Dich: Möglichkeit zur Weiterbildung Deiner Skills durch Schulungen & Workshops</li>
        </ul>
      </div>
      <div class="wrapper-row-inner max-w row d-flex flex-column text-center">
        <h3>Productmanager & Consultant im Bereich Mobile Anwendungen (Apps, AR, VR, 3D) (m/w/d)</h3>
        <p>&nbsp;</p>
        <h4>Deine Aufgaben</h4>
        <ul>
          <li>Du leitest unseren Fachbereich, die Mobile Etage</li>
          <li>Dein Aufgabenschwerpunkt liegt in der Kundenberatung und im Vertrieb</li>
          <li>Du entwickelst gemeinsam mit der Geschäftsführung die strategische Ausrichtung</li>
          <li>Du steuerst die Wirtschaftlichkeit des Unternehmensbereichs</li>
          <li>Du führst und entwickelst das Team bestehend aus Projektleitern, Programmierern & Designern</li>
          <li>In großen Projekten unterstützt Du in der Projektleitung</li>
          <li>Du stehst im Austausch mit unseren Kunden</li>
          <li>Du bist Ideengeber & Lösungsfinder für unsere Kunden</li>
        </ul>
        <h4>Das bringst du mit</h4>
        <ul>
          <li>Du tauschst Dich gerne mit anderen aus und hast Freude daran, unsere Kunden zu beraten</li>
          <li>Technikaffinität: Du brennst für state-of-the-art Entwicklungen & hast Interesse an Kommunikationsmedien der Zukunft</li>
          <li>Du hast Freude daran, andere zu unterstützen</li>
          <li>Du verfolgst ambitioniert Deine Ziele</li>
          <li>Dein Team & dein Arbeitsumfeld sind Dir wichtig - Du bist kein einsamer Wolf</li>
          <li>Am liebsten arbeitest Du eigenverantwortlich</li>
          <li>Deine Begeisterung ist für den Kunden und das Team zu spüren</li>
          <li>Vielleicht hast Du schon Erfahrungen im Bereich Vertrieb oder Projektleitung gesammelt</li>
        </ul>
        <h4>Unser Angebot für dich</h4>
        <ul>
          <li>Wir bieten Dir die Möglichkeit eigenständig einen Unternehmensbereich zu leiten, zu formen und weiterzuentwickeln</li>
          <li>Gleichzeitig bieten wir Dir ein starkes, erfahrenes Leitungsteam als Unterstützung</li>
          <li>Wir wollen Dich und Dein Team weiterentwickeln und unterstützen Dich mit allen nötigen Maßnahmen (Schulungen, Webinare, Zertifiktate & Co.)</li>
          <li>Tolles Team! Wir stehen füreinander ein - verbringen gerne Zeit miteinander und gehen einmal pro Jahr auf große Fahrt</li>
          <li>Flache Hierarchie, kurze Entscheidungswege und Freiraum, Dich selbst zu entfalten</li>
          <li>Wir respektieren Dich und Deine Freizeit im hohen Maße, daher wählst Du auch Deine Urlaubszeiten frei und kurzfristig</li>
          <li>Freie Zeiteinteilung - komm' und gehe mal später, mal früher oder arbeite im Homeoffice</li>
          <li>Ruhiges Arbeitsumfeld in kleinen Büros</li>
        </ul>
      </div>
      <div class="wrapper-row-inner max-w row d-flex flex-column text-center">
        <h3>Alles klar?</h3>
        <p>
          Das klingt alles cool? Du hast noch Fragen?<br />
          Wir freuen uns über Deine Kontaktaufnahme ganz unkompliziert per WhatsApp unter der Nummer 0176-10450115. Ricarda steht Dir für alle Rückfragen zur Verfügung. Alternativ kannst Du uns Dein Bewerbungs-PDF an jobs@die-etagen.de schicken.
        </p>
        <h3>Wer wir sind</h3>
        <p>
          Die Etagen sind eine Digitalagentur mit Sitz in Osnabrück und Hamburg. Seit mehr als 20 Jahren begleiten wir unsere Kunden (u. a. BMW, B. Braun, BROOKS Running, ABUS,
          FRIMO, Jung von Matt) mit innovativen Ideen und
          zielgruppengerechter Kommunikation.
          Rund 50 Digitalspezialist:Innen realisieren Projekte
          verschiedenster Art, von VR-Games bis hin zu Industrie- und Medizinvisualisierungen.
        </p>
      </div>
    </div>
    <div class="wrapper-row at-bottom on-stage">
      <div class="wrapper-row-inner max-w col  nsb-section">
        <div class="image-row">
          <div class="image-row-inner loaded">
            <img src="../assets/etagen-jobs.png" alt="" class="image-row-content d-block img-fluid mx-auto w-75" />
          </div>
        </div>
      </div>
    </div>
    <div class="wrapper-row fp-section fp-table wrapper-padding d-flex flex-row nsb-section">
      <div class="wrapper-row-inner max-w row">
        <h3 class="text-white process">So funktioniert der Bewerbungsprozess bei uns:</h3>
        <div class="wrapper-row-inner col-md-6 process">
          <h4>1. ZEIG UNS WER DU BIST!</h4>
          <p>
            Du schickst uns Deine Unterlagen - und dabei kommt es uns nicht darauf an, dass Du Dich an Schema F hältst,
            sondern es geht viel mehr darum, dass wir erkennen, wer Du bist, was Du mitbringst und worauf Du Bock hast.
            Super cool wäre es, wenn Du auch ein paar Arbeitsreferenzen nennen / zeigen könntest. Dies ist aber kein
            Muss. Am besten per Mail an jobs@die-etagen.de oder per WhatsApp an unsere Feelgood Managerin Ricarda
            0176-10450115. Ihr kannst Du auch vorab Fragen schicken.
          </p>
          <h4>2. WIR MELDEN UNS!</h4>
          <p>
            Wir schauen Deine Sachen dann schnell an - und wenn wir das Gefühl haben, dass es passen könnte,
            kontaktieren wir Dich umgehend und möchten uns gern mit Dir treffen.
          </p>
        </div>

        <div class="wrapper-row-inner col-md-6 process">
          <h4>3. MEET & GREET</h4>
          <p>
            Damit Du weißt, wie sich unsere Agentur anfühlt, möchten wir Dir gern schnell so viele Kollegen wie möglich
            zeigen & Deine Aufgaben verdeutlichen. Am besten klappt das, wenn Du mal einen Tag zu uns kommst. Da das
            meistens ja aber eher schwierig ist, finden wir auch andere Wege.
          </p>
          <h4>4. GEMEINSAM DURCHSTARTEN</h4>
          <p>
            Und wenn es dann passt, sprechen wir sehr schnell darüber, wie Du gern arbeiten möchtest (in der Agentur,
            Remote, Teils / Teils), welche Arbeitszeiten Du Dir wünschst (oder z.B. nur eine 30 Stunden Woche) und wie
            Du Dich bei uns einbringen kannst, damit wir gemeinsam voran gehen.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const axios = require("axios").default;
export default {
  name: "Jobs",
  data() {
    return {
      jobs: []
    };
  },
  created() {
    this.fetchData()
    console.log(this.jobs)
  },
  methods: {
    fetchData() {
      var self = this;
      axios({
        method: "get",
        url: "https://api.die-etagen.de/jsonapi/media/job?include=field_media_file_1",
        headers: {
          "Accept-Encoding": "deflate",
          "Content-Type": "application/vnd.api+json"
        }
      }).then(function(response) {
        // handle success
        for (var nameKey in response.data.data) {
          for (var linkKey in response.data.included) {
            if (linkKey === nameKey) {
              self.jobs.push({
                link: "https://api.die-etagen.de/" + response.data.included[linkKey].attributes.uri.url,
                name: response.data.data[nameKey].attributes.name,
                category: response.data.data[nameKey].attributes.field_category
              });
            }
          }
        }
      });
    }
  }
};
</script>

<style scoped>
.job-desc {
  padding-right: 10rem;
}
ul {
  list-style-type: none;
}
.text-white {
  width: 100%;
}
.text-white.process,
.text-white {
  padding-right: 15px;
  padding-left: 15px;
}
@media screen and (min-width: 1200px) {
  .process {
    padding: 0 6rem;
  }
  .text-white.process {
    padding-bottom: 2rem;
    padding-left: 6rem;
  }
}
</style>
